import React from "react";
import { MigrationSetWithImports } from "../../../shared/types";
import {
  HeadingInfoRow,
  Loader,
  Modal,
  StandardButton,
} from "../../../components";
import { Archive } from "lucide-react";
import { useMigration } from "../../../hooks";
import { COLOURS, useSmokeballApp } from "../../../shared";
import { useRegion } from "../../../shared/utils/region";
import { getRegionSpecificSummaryExpenseHeader } from "../../../shared/utils/constants";
 
export const SummaryInfo: React.FC<{
  migrationSet: MigrationSetWithImports;
}> = ({ migrationSet }) => {
  const getViewSummaryCounts = (
    migrationSet: MigrationSetWithImports,
  ): {
    contacts: string;
    staff: string;
    matters: string;
    matterOpeningBalances: string;
    matterRelationships: string;
    activities: string;
    unbilledTimeAndFees: string;
    unbilledExpenses: string;
    invoices: string;
    memos: string;
    tasks: string;
    safeCustodyPackets: string;
    safeCustodyDocuments: string;
  } => {
    const { imports } = migrationSet;
 
    // Counts
    let contactsCount = 0;
    let staffCount = 0;
    let mattersCount = 0;
    let matterOpeningBalancesCount = 0;
    let matterRelationshipsCount = 0;
    let activitiesCount = 0;
    let unbilledTimeAndFeesCount = 0;
    let unbilledExpensesCount = 0;
    let invoicesCount = 0;
    let tasksCount = 0;
    let memosCount = 0;
    let safeCustodyDocumentsCount = 0;
    let safeCustodyPacketsCount = 0;
 
    // Errors
    let contactsErrors = 0;
    let staffErrors = 0;
    let mattersErrors = 0;
    let matterOpeningBalancesErrors = 0;
    let matterRelationshipsErrors = 0;
    let activitiesErrors = 0;
    let unbilledTimeAndFeesErrors = 0;
    let unbilledExpensesErrors = 0;
    let invoicesErrors = 0;
    let tasksErrors = 0;
    let memosErrors = 0;
    let safeCustodyDocumentsErrors = 0;
    let safeCustodyPacketsErrors = 0;
 
    if (Array.isArray(imports)) {
      imports.forEach((importItem) => {
        const count =
      migrationSet.loadStatus !== "Load-Complete"
        ? importItem.uploadTotalRecordCount
        : (importItem.loadTotalRecordCount + importItem.loadErrorCount) || importItem.uploadTotalRecordCount;
        const errors = importItem.loadErrorCount;
 
        switch (importItem.importType) {
          case "contact":
            contactsCount = count;
            contactsErrors = errors;
            break;
          case "staff":
            staffCount = count;
            staffErrors = errors;
            break;
          case "matter":
            mattersCount = count;
            mattersErrors = errors;
            break;
          case "matterOpeningBalance":
            matterOpeningBalancesCount = count;
            matterOpeningBalancesErrors = errors;
            break;
          case "matterRelationship":
            matterRelationshipsCount = count;
            matterRelationshipsErrors = errors;
            break;
          case "activity":
            activitiesCount = count;
            activitiesErrors = errors;
            break;
          case "unbilledTimeAndFee":
            unbilledTimeAndFeesCount = count;
            unbilledTimeAndFeesErrors = errors;
            break;
          case "unbilledExpense":
            unbilledExpensesCount = count;
            unbilledExpensesErrors = errors;
            break;
          case "invoice":
            invoicesCount = count;
            invoicesErrors = errors;
            break;
          case "task":
            tasksCount = count;
            tasksErrors = errors;
            break;
          case "memo":
            memosCount = count;
            memosErrors = errors;
            break;
          case "safeCustodyPacket":
            safeCustodyPacketsCount = count;
            safeCustodyPacketsErrors = errors;
            break;
          case "safeCustodyDocument":
            safeCustodyDocumentsCount = count;
            safeCustodyDocumentsErrors = errors;
            break;
          default:
            break;
        }
      });
    }
 
    const formatCountWithError = (
      count: number | null,
      errors: number | null,
    ) => {
      let effectiveCount = count ?? 0;
      let formattedCount = effectiveCount.toLocaleString();
 
      // Ensure errors is treated as 0 if it is null, for the comparison and formatting
      let effectiveErrors = errors ?? 0;
      let formattedErrors = effectiveErrors.toLocaleString();
 
      return effectiveErrors > 0
        ? `${formattedCount} (${formattedErrors} failed)`
        : `${formattedCount}`;
    };
 
    return {
      contacts: formatCountWithError(contactsCount, contactsErrors),
      staff: formatCountWithError(staffCount, staffErrors),
      matters: formatCountWithError(mattersCount, mattersErrors),
      matterOpeningBalances: formatCountWithError(
        matterOpeningBalancesCount,
        matterOpeningBalancesErrors,
      ),
      matterRelationships: formatCountWithError(
        matterRelationshipsCount,
        matterRelationshipsErrors,
      ),
      activities: formatCountWithError(activitiesCount, activitiesErrors),
      unbilledTimeAndFees: formatCountWithError(
        unbilledTimeAndFeesCount,
        unbilledTimeAndFeesErrors,
      ),
      unbilledExpenses: formatCountWithError(
        unbilledExpensesCount,
        unbilledExpensesErrors,
      ),
      invoices: formatCountWithError(invoicesCount, invoicesErrors),
      tasks: formatCountWithError(tasksCount, tasksErrors),
      memos: formatCountWithError(memosCount, memosErrors),
      safeCustodyPackets: formatCountWithError(
        safeCustodyPacketsCount,
        safeCustodyPacketsErrors,
      ),
      safeCustodyDocuments: formatCountWithError(
        safeCustodyDocumentsCount,
        safeCustodyDocumentsErrors,
      ),
    };
  };
 
  const region = useRegion(migrationSet?.accountBillingCountryCode);
 
  // Get summary counts
  const summaryCounts = getViewSummaryCounts(migrationSet);
 
  return (
    <>
      {parseInt(summaryCounts.staff) > 0 && (
        <HeadingInfoRow lineHeight="120%">
          Staff: {summaryCounts.staff}
        </HeadingInfoRow>
      )}
      {parseInt(summaryCounts.contacts) > 0 && (
        <HeadingInfoRow lineHeight="120%">
          Contacts: {summaryCounts.contacts}
        </HeadingInfoRow>
      )}
      {parseInt(summaryCounts.matters) > 0 && (
        <HeadingInfoRow lineHeight="120%">
          Matters: {summaryCounts.matters}
        </HeadingInfoRow>
      )}
      {parseInt(summaryCounts.matterRelationships) > 0 && (
        <HeadingInfoRow lineHeight="120%">
          Matter Roles: {summaryCounts.matterRelationships}
        </HeadingInfoRow>
      )}
      {parseInt(summaryCounts.matterOpeningBalances) > 0 && (
        <HeadingInfoRow lineHeight="120%">
          Opening Balances: {summaryCounts.matterOpeningBalances}
        </HeadingInfoRow>
      )}
      {parseInt(summaryCounts.activities) > 0 && (
        <HeadingInfoRow lineHeight="120%">
          Activity Types: {summaryCounts.activities}
        </HeadingInfoRow>
      )}
      {parseInt(summaryCounts.unbilledTimeAndFees) > 0 && (
        <HeadingInfoRow lineHeight="120%">
          Time and Fee Entries: {summaryCounts.unbilledTimeAndFees}
        </HeadingInfoRow>
      )}
      {parseInt(summaryCounts.unbilledExpenses) > 0 && (
        <HeadingInfoRow lineHeight="120%">
        {getRegionSpecificSummaryExpenseHeader(region)}: {summaryCounts.unbilledExpenses}
      </HeadingInfoRow>
      )}
      {parseInt(summaryCounts.invoices) > 0 && (
        <HeadingInfoRow lineHeight="120%">
          Invoices: {summaryCounts.invoices}
        </HeadingInfoRow>
      )}
      {parseInt(summaryCounts.tasks) > 0 && (
        <HeadingInfoRow lineHeight="120%">
          Tasks: {summaryCounts.tasks}
        </HeadingInfoRow>
      )}
      {parseInt(summaryCounts.memos) > 0 && (
        <HeadingInfoRow lineHeight="120%">
          Memos: {summaryCounts.memos}
        </HeadingInfoRow>
      )}
      {parseInt(summaryCounts.safeCustodyPackets) > 0 && (
        <HeadingInfoRow lineHeight="120%">
          Safe Custody Packets: {summaryCounts.safeCustodyPackets}
        </HeadingInfoRow>
      )}
      {parseInt(summaryCounts.safeCustodyDocuments) > 0 && (
        <HeadingInfoRow lineHeight="120%">
          Safe Custody Documents: {summaryCounts.safeCustodyDocuments}
        </HeadingInfoRow>
      )}
    </>
  );
};
 
type SummaryInfoModalProps = {
  migrationSetId: number;
  onClose: () => void;
};
 
export const SummaryInfoModal = ({
  migrationSetId,
  onClose,
}: SummaryInfoModalProps) => {
  const { auth, context } = useSmokeballApp();
  const accountId = context?.firmId || "";
  const token = auth?.token;
  const { data, isLoading } = useMigration({
    accountId,
    migrationSetId,
    token,
  });
  const region = useRegion(data?.accountBillingCountryCode);
 
  if (isLoading || !data || !region || region === "Not set") {
    return <Loader />;
  }
 
  return (
    <Modal
      width="40rem"
      icon={
        <Archive
          color={COLOURS.summaryModalIconColor}
          strokeWidth={2}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        />
      }
      buttons={[
        {
          Button: StandardButton,
          label: "Close",
          action: onClose,
        },
      ]}
    >
      <SummaryInfo migrationSet={data} />
    </Modal>
  );
};
